.App__w750___30b1A {
  min-width: 750px;
}

.App__mainDiv___ddp8T {
  background: url(/8f1b04076031eec76a0da1c0ee705cfc.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.App__mainContainer___2fwYT {
  margin: 0 3rem 3rem 3rem;
  max-width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.App__windowContainer___uRFp4 {
  display: flex;
  justify-content: center;
  margin: 10% 0;
}
