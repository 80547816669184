.HeadBar__headerBar___2IUyo {
  margin-bottom: 5rem;
  z-index: 100;
}

.HeadBar__toolBar___ly3Mj {
  display: grid;
  position: relative;
}

.HeadBar__headerButtonGroup___ofJq1 {
  display: flex;
}

.HeadBar__startButton___3wCK4 {
  font-weight: 900;
  font-family: "VT323", monospace;
}

.HeadBar__startButton___3wCK4 > div {
  display: flex;
  align-items: center;
}

.HeadBar__buttonText___EqsDe {
  font-family: "VT323", monospace;
  margin: 0 1.8rem;
}

.HeadBar__commandLineButton___3CoLV {
  margin-top: -5px;
}

.HeadBar__buttonText___EqsDe .HeadBar__commandLineIcon___2w4tD {
  display: inline-block;
  transition: all 200ms ease-in-out;
}
.HeadBar__buttonText___EqsDe:hover .HeadBar__commandLineIcon___2w4tD {
  transform: scale(1.3);
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.HeadBar__verticalLine___3DJZv {
  margin: 0 0.5rem !important;
}

.HeadBar__winLogo___EQgdV {
  margin-right: 5px;
  width: 20px;
}

.HeadBar__headerAvatar___BV-_X {
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
  animation: HeadBar__wiggle___tg-m6 2000ms infinite;
  animation-timing-function: linear;
}

@keyframes HeadBar__wiggle___tg-m6 {
  0% {
    transform: translate(1px, 0);
    -ms-transform: translate(1px, 0);
    -webkit-transform: translate(1px, 0);
  }
  10% {
    transform: translate(-1px, 0);
    -ms-transform: translate(-1px, 0);
    -webkit-transform: translate(-1px, 0);
  }
  20% {
    transform: translate(1px, 0);
    -ms-transform: translate(1px, 0);
    -webkit-transform: translate(1px, 0);
  }
}

@media screen and (max-width: 720px) {
  .HeadBar__headerButtonGroup___ofJq1 {
    flex-direction: row;
  }
  .HeadBar__buttonText___EqsDe {
    flex: 0 0 20vw;
    margin: 0;
  }
}
