body {
  font-family: "VT323", monospace !important;
}

header {
  font-family: "Tinos", serif;
}

button {
  font-family: "VT323", monospace;
}
